import _ from '@/utils/lodash'
import { likeRegExp } from '@/offline/database'
import { GRUPO_CHECKLIST, ACCION_EJEC } from '@/utils/consts'
import { toDate, endOfDay } from '@/utils/date'
import colors from 'vuetify/lib/util/colors'

export default {
  async selectTimportanciaMaterial (Vue) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.timportanciaMaterial.select({
      where: Vue.$offline.db.op.and(
        tables.timportancia_material.estado.gt(0)
      )
    })
  },
  _selectCountPreguntasBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas')
      )
      .from(tables.orden_trabajo_matsist)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
      )
  },
  _selectCountPreguntasContestadasOrdenTrabajoMatsist (Vue, idordenTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    return this._selectCountPreguntasBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.idgrado_anomalia.isNotNull()
        )
      )
      .exec()
  },
  _selectCountPreguntasNoContestadasOrdenTrabajoMatsist (Vue, idordenTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    return this._selectCountPreguntasBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.pregunta_checklist_ot.idgrado_anomalia.isNull()
        )
      )
      .exec()
  },
  _selectAcciones (Vue, idordenTrabajoMatsist, idestAccion) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.accion_ejec.idest_accion_ejec.in(idestAccion)
        )
      )
      .orderBy(tables.maccion.orden)
      .exec()
  },
  async _addSubqueriesMaterialAfectado (Vue, rows) {
    for (let row of rows) {
      row.conAccionesPendientes = false
      // acciones
      row.acciones = []
      const acciones = await this._selectAcciones(
        Vue, row.orden_trabajo_matsist.idorden_trabajo_matsist,
        [ACCION_EJEC.estados.pendiente, ACCION_EJEC.estados.realizada]
      )
      for (let accion of acciones) {
        row.acciones.push({
          desc_acciones: accion.maccion.descripcion_corta,
          clasificacion: accion.maccion.clasificacion,
          idaccion_ejec: accion.accion_ejec.idaccion_ejec,
          color: accion.accion_ejec.idest_accion_ejec === ACCION_EJEC.estados.realizada ? colors.green.base : colors.red.base,
          idest_accion_ejec: accion.accion_ejec.idest_accion_ejec,
          facturable: accion.accion_ejec.facturable,
          idparte_trabajo: accion.accion_ejec.idparte_trabajo,
        })
        if (accion.accion_ejec.idest_accion_ejec === ACCION_EJEC.estados.pendiente) {
          row.conAccionesPendientes = true
        }
      }
      // porcentaje de progreso segun preguntas contestadas
      const contestadas = await this._selectCountPreguntasContestadasOrdenTrabajoMatsist(
        Vue, row.orden_trabajo_matsist.idorden_trabajo_matsist
      )
      const preguntasContestadas = contestadas[0].cant_preguntas
      const noContestadas = await this._selectCountPreguntasNoContestadasOrdenTrabajoMatsist(
        Vue, row.orden_trabajo_matsist.idorden_trabajo_matsist
      )
      const preguntasNoContestadas = noContestadas[0].cant_preguntas
      row.porcentajeProgreso = Math.round(
        preguntasContestadas * 100 / (preguntasNoContestadas + preguntasContestadas)
      )
      row.conPreguntasSinContestar = !isNaN(row.porcentajeProgreso) && row.porcentajeProgreso < 100
      // resultado de preguntas del material (si tiene preguntas de checklist)
      if (!isNaN(row.porcentajeProgreso)) {
        row.resultado = await Vue.$offline.ordenTrabajoMatsist.resultadoGrupo(
          row.orden_trabajo_matsist.idorden_trabajo_matsist
        )
      }
    }
    return rows
  },
  _selectMaterialAfectadoBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.parte_trabajo,
        tables.parte_trabajo_matsist.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .innerJoin(
        tables.sistema,
        tables.subsis.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
  },
  async _selectInMaterialChecklist (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    const materialChecklist = await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.distinct(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist
        ).as('idorden_trabajo_matsist')
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.grupo_checklist_ot.clasificacion.eq(GRUPO_CHECKLIST.clasificacion.materiales)
        )
      )
      .exec()
    return _.map(materialChecklist, 'idorden_trabajo_matsist')
  },
  async _selectInMaterialGrupoChecklist (Vue, idgrupoChecklistOt) {
    const tables = Vue.$offline.db.tables
    const materialGrupoChecklist = await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.distinct(
          tables.pregunta_checklist_ot.idorden_trabajo_matsist
        ).as('idorden_trabajo_matsist')
      )
      .from(tables.pregunta_checklist_ot)
      .where(
        Vue.$offline.db.op.and(
          tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt)
        )
      )
      .exec()
    return _.map(materialGrupoChecklist, 'idorden_trabajo_matsist')
  },
  async _selectInMaterialConDato (Vue, idparteTrabajo, idtdatoArticulo, valor) {
    const reQ = likeRegExp(valor)
    const tables = Vue.$offline.db.tables
    const materialConDato = await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.distinct(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist
        ).as('idparte_trabajo_matsist')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.dato_material_sistema,
        tables.material_sistema.idmaterial_sistema.eq(tables.dato_material_sistema.idmaterial_sistema)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.material_sistema.estado.gt(0),
          tables.material_sistema.fdesinstalacion.isNull(),
          tables.dato_material_sistema.idtdato_articulo.eq(idtdatoArticulo),
          tables.dato_material_sistema.valor.match(reQ),
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
        )
      )
      .exec()
    return _.map(materialConDato, 'idparte_trabajo_matsist')
  },
  selectMaterialAfectadoMetadata (sorter) {
    const metadata = {
      sorter_desc: [
        { label: 'Nº de orden', name: 'n_orden', field: 'material_sistema.n_orden' },
        { label: 'Nº de serie', name: 'nserie', field: 'material_sistema.nserie' },
        { label: 'Nº de elemento', name: 'nelemento', field: 'material_sistema.nelemento' },
        { label: 'Nº de etiqueta', name: 'netiqueta', field: 'material_sistema.netiqueta' },
        { label: 'Código', name: 'codigo', field: 'material_sistema.codigo' },
        { label: 'Descripción', name: 'descripcion', field: 'material_sistema.descripcion' },
      ],
    }
    if (!_.isEmpty(sorter)) {
      metadata.sorter = sorter
    } else {
      metadata.sorter = [
        { field: 'n_orden', asc: true },
      ]
    }
    return metadata
  },
  async selectMaterialAfectado (
    Vue, filter, search, sorter, page, idchecklistOt, idgrupoChecklistOt, idparteTrabajo, idsubsis, retirado
  ) {
    const tables = Vue.$offline.db.tables
    let metadata = this.selectMaterialAfectadoMetadata(sorter)
    let where = []
    let whereSearch = []
    let whereFilter = []
    let whereExtra = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.material_sistema.nserie.match(reQ),
          tables.material_sistema.nelemento.match(reQ),
          tables.material_sistema.netiqueta.match(reQ),
          tables.material_sistema.descripcion.match(reQ)
        )
      )
    }
    // campos fijos
    if (filter.descripcion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.descripcion.match(likeRegExp(filter.descripcion.value))
        )
      )
    }
    if (filter.codigo.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.codigo.match(likeRegExp(filter.codigo.value))
        )
      )
    }
    if (filter.nelemento.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.nelemento.match(likeRegExp(filter.nelemento.value))
        )
      )
    }
    if (filter.nserie.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.nserie.match(likeRegExp(filter.nserie.value))
        )
      )
    }
    if (filter.netiqueta.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.netiqueta.match(likeRegExp(filter.netiqueta.value))
        )
      )
    }
    if (filter.ubicacion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.ubicacion.match(likeRegExp(filter.ubicacion.value))
        )
      )
    }
    if (filter.num_zona.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.num_zona.match(likeRegExp(filter.num_zona.value))
        )
      )
    }
    if (filter.fdesdeInstalacion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.fsistema.gte(toDate(filter.fdesdeInstalacion.value))
        )
      )
    }
    if (filter.fhastaInstalacion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.fsistema.lte(endOfDay(toDate(filter.fhastaInstalacion.value)))
        )
      )
    }
    if (filter.fultRetimbrado.value) {
      let fultRetimbrado = toDate(filter.fultRetimbrado.value)
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.fult_retimbrado.gte(fultRetimbrado),
          tables.material_sistema.fult_retimbrado.lte(endOfDay(fultRetimbrado)),
        )
      )
    }
    if (filter.fultRevision.value) {
      let fultRevision = toDate(filter.fultRevision.value)
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.fultrevision.gte(fultRevision),
          tables.material_sistema.fultrevision.lte(endOfDay(fultRevision)),
        )
      )
    }
    if (filter.ffabricacion.value) {
      let ffabricacion = toDate(filter.ffabricacion.value)
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.ffabricacion.gte(ffabricacion),
          tables.material_sistema.ffabricacion.lte(ffabricacion),
        )
      )
    }
    if (filter.idtimportancia_material.value && filter.idtimportancia_material.value.length) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.idtimportancia_material.in(filter.idtimportancia_material.value),
        )
      )
    }
    // campos dinámicos de TDATO_ARTICULO
    const dynamicFilterFields = _.filter(filter, (field) => {
      if (field.dynamic && field.value) {
        return field
      }
    })
    for (const dynamicField of dynamicFilterFields) {
      const inMaterialParteConDato = await this._selectInMaterialConDato(
        Vue, idparteTrabajo, dynamicField.id, dynamicField.value
      )
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.in(inMaterialParteConDato),
        )
      )
    }
    // filtros querystring
    if (idchecklistOt) {
      const inMaterialChecklist = await this._selectInMaterialChecklist(Vue, idchecklistOt)
      whereExtra.push(
        Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.in(inMaterialChecklist)
        )
      )
    }
    if (idgrupoChecklistOt) {
      const inMaterialGrupoChecklist = await this._selectInMaterialGrupoChecklist(Vue, idgrupoChecklistOt)
      whereExtra.push(
        Vue.$offline.db.op.and(
          tables.orden_trabajo_matsist.idorden_trabajo_matsist.in(inMaterialGrupoChecklist)
        )
      )
    }
    if (idparteTrabajo) {
      whereExtra.push(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo)
        )
      )
    }
    if (idsubsis) {
      whereExtra.push(
        Vue.$offline.db.op.and(
          tables.subsis.idsubsis.eq(idsubsis)
        )
      )
    }
    if (retirado) {
      whereExtra.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.idalmacen.isNotNull()
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.material_sistema.estado.gt(0),
        ...whereSearch,
        ...whereFilter,
        ...whereExtra
      )
    )
    let query = this._selectMaterialAfectadoBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
    query = Vue.$offline.db.applySorter(query, metadata)
    const rows = await query.exec()
    return [await this._addSubqueriesMaterialAfectado(Vue, rows), metadata]
  },
  async selectMaterialAfectadoRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows = await this._selectMaterialAfectadoBase(Vue)
      .where(tables.parte_trabajo_matsist.idparte_trabajo_matsist.in(pks))
      .exec()
    return this._addSubqueriesMaterialAfectado(Vue, rows)
  },
  async rowParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          Vue.$offline.db.op.or(
            tables.checklist_ot.estado.gt(0),
            tables.checklist_ot.idchecklist_ot.isNull(),
          )
        )
      )
      .exec())[0]
  },
  async rowChecklistOt (Vue, idgrupoChecklistOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.grupo_checklist_ot)
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
      .where(tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt))
      .exec())[0]
  },
  selectTDatosArticulo (Vue, idparteTrabajo, idsubsis) {
    const tables = Vue.$offline.db.tables
    let whereSubsis = []
    if (idsubsis) {
      whereSubsis.push(
        Vue.$offline.db.op.and(
          tables.material_sistema.idsubsis.eq(idsubsis)
        )
      )
    }
    return Vue.$offline.db
      .select(
        ...Vue.$offline.tdatoArticulo.columns,
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.ficha_tecnica,
        tables.articulo.idficha_tecnica.eq(tables.ficha_tecnica.idficha_tecnica)
      )
      .innerJoin(
        tables.tdato_articulo,
        tables.ficha_tecnica.idficha_tecnica.eq(tables.tdato_articulo.idficha_tecnica)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.tdato_articulo.para_material_sis.eq(true),
          tables.ficha_tecnica.estado.gt(0),
          tables.tdato_articulo.estado.gt(0),
          ...whereSubsis,
        )
      )
      .groupBy(
        ...Vue.$offline.tdatoArticulo.columns,
      )
      .orderBy(tables.material_sistema.idsubsis)
      .orderBy(tables.tdato_articulo.orden)
      .exec()
  },
  selectTDatoArticuloValor (Vue, idtdatoArticulo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.tdatoArticuloValor.select({
      where: tables.tdato_articulo_valor.idtdato_articulo.eq(idtdatoArticulo)
    })
  },
}
