<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      :empty-message="emptyMessage"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :page-loaded="pageLoaded"
      :init-filter="initFilter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      pk-name="parte_trabajo_matsist.idparte_trabajo_matsist"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <v-fade-transition>
          <lparte-trabajo-material-afectado-list-item
            v-show="verCompletado || slotProps.item.conAccionesPendientes || slotProps.item.conPreguntasSinContestar"
            :item="slotProps.item"
            @click-accion="clickAccion"
            @click-avatar="clickItemAvatar"
          />
        </v-fade-transition>
      </template>
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="idchecklistOt"
      />
    </b10-list>
    <lparte-trabajo-material-afectado-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b-10-bottom-navigation
      :buttons="bottomNavBarButtons"
      @click-bottom-nav-bar-button="clickBottomNavBarButton"
    />
    <buscar-material-afectado
      v-if="dbReady"
      :showing.sync="showingDialogs.buscarMaterialAfectado"
      :idparte-trabajo="routeParams.idparte_trabajo"
      :idsubsis="routeQuery.idsubsis"
      :idgrupo-checklist-ot="routeQuery.idgrupo_checklist_ot"
      :busqueda-avanzada="false"
    />
    <select-almacen-form
      v-if="dbReady"
      :key="selectAlmacenFormKey"
      :showing.sync="showingDialogs.selectAlmacenForm"
      :idparte-trabajo="routeParams.idparte_trabajo"
      @add-almacen="almacenSelected"
    />
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './LparteTrabajoMaterialAfectadoListData'
import LparteTrabajoMaterialAfectadoListItem from './components/LparteTrabajoMaterialAfectadoListItem'
import LparteTrabajoMaterialAfectadoListLegend from './components/LparteTrabajoMaterialAfectadoListLegend'
import SelectAlmacenForm from '../components/SelectAlmacenForm'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { get, sync } from 'vuex-pathify'
import { TDATO_ARTICULO, PARTE_TRABAJO, CHECKLIST, ACCION_EJEC, MACCION, TINCLUSION } from '@/utils/consts'
import { NextRoute } from '@/router'
import BuscarMaterialAfectado from '../components/BuscarMaterialAfectado'
import { encodeBase64 } from '@/utils/router'

const pageStoreName = 'pagesOfflineLparteTrabajoMaterialAfectadoList'

export default {
  components: {
    LparteTrabajoMaterialAfectadoListItem,
    LparteTrabajoMaterialAfectadoListLegend,
    VolverParteChecklistMenuItems,
    BuscarMaterialAfectado,
    SelectAlmacenForm
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        addMaterialNuevo: {
          title: 'Material nuevo',
          visible: true,
          icon: 'plus'
        },
        addMaterialSistema: {
          title: 'Material desde el sistema',
          visible: true,
          icon: 'plus'
        },
        marcarTodoCorrecto: {
          title: 'Marcar todo correcto',
          visible: true,
          icon: 'checkAll'
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false,
        buscarMaterialAfectado: false,
        selectAlmacenForm: false,
      },
      PARTE_TRABAJO,
      parteTrabajo: {},
      emptyMessage: '',
      grupoChecklistOt: {},
      subsis: {},
      accionSeleccionadaData: {},
      selectAlmacenFormKey: 0,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    verCompletado: sync(`${pageStoreName}/verCompletado`),
    usuarioIdtecnico: get('usuario/idtecnico'),
    estadoPartePendiente () {
      return (
        this.parteTrabajo &&
        this.parteTrabajo.parte_trabajo &&
        this.parteTrabajo.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      )
    },
    estadoChecklistPendiente () {
      return (
        this.parteTrabajo &&
        this.parteTrabajo.checklist_ot &&
        this.parteTrabajo.checklist_ot.estado === CHECKLIST.estados.pendiente
      )
    },
    iconVerCompletado () {
      return this.verCompletado ? 'hide' : 'show'
    },
    idchecklistOt () {
      if (this.parteTrabajo && this.parteTrabajo.checklist_ot && this.parteTrabajo.checklist_ot.idchecklist_ot) {
        return this.parteTrabajo.checklist_ot.idchecklist_ot
      } else {
        return null
      }
    },
  },
  async created () {
    // routeQuery.idgrupo_checklist_ot: filtro por grupo de checklist
    // routeQuery.idsubsis: filtro por subsistema
    // routeQuery.retirado: filtro por material retirado
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.routeQuery.retirado) {
        this.title = 'Material retirado'
        this.emptyMessage = 'No se encontraron materiales retirados'
      } else {
        if (this.routeQuery.idgrupo_checklist_ot) {
          this.grupoChecklistOt = await this.$offline.grupoChecklistOt.row(this.routeQuery.idgrupo_checklist_ot)
          this.title = `Material afectado ${this.grupoChecklistOt.grupo_checklist_ot.descripcion}`
        } else if (this.routeQuery.idsubsis) {
          this.subsis = await this.$offline.subsis.row(this.routeQuery.idsubsis)
          this.title = `Material afectado ${this.subsis.subsis.descripcion}`
        } else {
          this.title = 'Material afectado'
        }
        this.emptyMessage = 'No se encontraron materiales afectados'
      }
      this.parteTrabajo = await Data.rowParteTrabajo(this, this.routeParams.idparte_trabajo)
      this.toolbarOptions.addMaterialNuevo.visible = this.estadoPartePendiente && !this.routeQuery.retirado
      this.toolbarOptions.addMaterialSistema.visible = this.estadoPartePendiente && !this.routeQuery.retirado
      this.toolbarOptions.marcarTodoCorrecto.visible = (
        !!this.routeQuery.idgrupo_checklist_ot &&
        this.estadoChecklistPendiente &&
        !this.routeQuery.retirado &&
        this.hasViewPerm(this.permissions.partes.marcarPreguntasTodoCorrecto)
      )
      this.loadNavigationBottomButtons()
      this.pageLoaded = true
    },
    async loadNavigationBottomButtons () {
      this.initNavigationBottom()
      this.addNavigationBottomButton(
        'Ver', 'ver', this.iconVerCompletado
      )
      this.addNavigationBottomButton(
        'Buscar', 'buscar', 'search', this.estadoPartePendiente
      )
    },
    async initFilter () {
      let tdatosArticulo = []
      // filtro de datos de ficha técnica por subsistema, grupo de checklist
      // o todo el material afectado del parte
      if (this.routeQuery.idgrupo_checklist_ot || this.routeQuery.idsubsis) {
        let idsubsis = this.routeQuery.idsubsis
        if (!idsubsis) {
          const checklistOtSubsis = await this.$offline.checklistOtSubsis.row(
            this.grupoChecklistOt.grupo_checklist_ot.idchecklist_ot_subsis
          )
          idsubsis = checklistOtSubsis.checklist_ot_subsis.idsubsis
        }
        if (idsubsis) {
          tdatosArticulo = await Data.selectTDatosArticulo(this, this.routeParams.idparte_trabajo, idsubsis)
        }
      } else {
        tdatosArticulo = await Data.selectTDatosArticulo(this, this.routeParams.idparte_trabajo, null)
      }
      if (tdatosArticulo.length > 0) {
        for (const tdato of tdatosArticulo) {
          const fieldname = `tdato_articulo_${tdato.tdato_articulo.idtdato_articulo}`
          if (!(fieldname in this.filter)) {
            let fieldType = 'string'
            let fieldItems = []
            let fieldSelectItemValue = ''
            if (tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.numero) {
              fieldType = 'number'
            } else if (tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.fecha) {
              fieldType = 'date'
            } else if (tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.seleccion) {
              fieldType = 'select'
              const tdatoArticuloValor = await Data.selectTDatoArticuloValor(
                this, tdato.tdato_articulo.idtdato_articulo
              )
              fieldItems.push(
                ..._.map(tdatoArticuloValor, 'tdato_articulo_valor.valor')
              )
            }
            await this.setStoreProperty(
              `filter@${fieldname}`,
              {
                label: tdato.tdato_articulo.descripcion,
                type: fieldType,
                value: null,
                field: fieldname,
                items: fieldItems,
                // selectItemValue y selectItemText iguales porque no hay campos label/valor en TDATO_ARTICULO
                selectItemValue: fieldSelectItemValue,
                selectItemText: fieldSelectItemValue,
                optional: true,
                dynamic: true,
                id: tdato.tdato_articulo.idtdato_articulo,
              }
            )
          }
        }
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.idtimportancia_material.items.length === 0
      ) {
        await this.setStoreProperty('filter@idtimportancia_material.items', await Data.selectTimportanciaMaterial(this))
      }
    },
    async loadItems () {
      this.loadingData = true
      try {
        let idchecklistOt = null
        if (this.routeQuery.idgrupo_checklist_ot) {
          // si viene desde checklist -> filtra por material del checklist, sino todo el material afectado
          // tenga o no preguntas
          idchecklistOt = this.idchecklistOt
        }
        const [dataset, metadata] = await Data.selectMaterialAfectado(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          idchecklistOt,
          this.routeQuery.idgrupo_checklist_ot,
          this.routeParams.idparte_trabajo,
          this.routeQuery.idsubsis,
          this.routeQuery.retirado
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectMaterialAfectadoRows(this, dirty.modified), dirty, 'parte_trabajo_matsist.idparte_trabajo_matsist'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__material-afectado-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idparte_trabajo_matsist: item.data.parte_trabajo_matsist.idparte_trabajo_matsist
        }
      })
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.addMaterialNuevo) {
        this.$appRouter.push({
          name: 'offline__articulo-list',
          params: {
            nextRoute: NextRoute(
              'offline__lparte-trabajo-material-afectado-add',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idarticulo: null,
              },
              {
                idsubsis: this.routeQuery.idsubsis,
              },
              true,
            ),
          },
          query: {
            idttarifa: this.parteTrabajo.orden_trabajo.idttarifa,
            idbanco_precio: this.parteTrabajo.orden_trabajo.idbanco_precio,
          }
        })
      } else if (option === this.toolbarOptions.addMaterialSistema) {
        this.$appRouter.push({
          name: 'offline__material-sistema-list',
          params: {
            idsistema: this.parteTrabajo.sistema.idsistema,
            nextRoute: NextRoute(
              'offline__lparte-trabajo-material-afectado-sistema-add',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idmaterial_sistema: null,
              },
              {
                idsubsis: this.routeQuery.idsubsis,
              },
              true,
            ),
          },
          query: {
            idparte_trabajo_exclude: this.routeParams.idparte_trabajo,
            _filter: encodeBase64({
              idsubsis: {
                // se lo mando como array porque es un select multiple
                value: [this.routeQuery.idsubsis],
              },
            }),
          },
        })
      } else if (option === this.toolbarOptions.marcarTodoCorrecto) {
        const res = await this.$alert.showConfirm(
          `¿Deseas marcar todas las preguntas no contestadas del grupo como correctas?`)
        if (res) {
          this.$loading.showManual('Marcando todo correcto')
          try {
            await this.$offline.grupoChecklistOt.updateGrupoContestadoCorrecto(
              this.routeQuery.idgrupo_checklist_ot,
              this.usuarioIdtecnico,
              this.routeParams.idparte_trabajo
            )
          } finally {
            this.$loading.hide()
          }
          this.resetList()
        }
      }
    },
    clickVerCompletado () {
      this.verCompletado = !this.verCompletado
      if (this.verCompletado) {
        this.$alert.showSnackbarInfo('Mostrando material sin acciones pendientes o preguntas de checklist sin contestar')
      }
      const verButtonIndex = _.findIndex(this.bottomNavBarButtons, {'name': 'ver'})
      this.bottomNavBarButtons[verButtonIndex].icon = this.iconVerCompletado
    },
    async reloadDirty () {
      if (this.routeQuery.idsubsis) {
        await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, this.routeQuery.idsubsis)
      }
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.accionSeleccionadaData.item.parte_trabajo_matsist.idparte_trabajo_matsist)
      await this.reloadDirtyItems()
    },
    async marcarAccionEjecRealizada () {
      try {
        this.$loading.showManual('Marcando acción realizada...')
        await this.$offline.accionEjec.marcarAccionEjecRealizadaSync(
          this.accionSeleccionadaData.accion.idaccion_ejec,
          this.idchecklistOt,
          this.accionSeleccionadaData.accion.facturable,
          null,
          TINCLUSION.suministro,
          this.usuarioIdtecnico,
          this.routeParams.idparte_trabajo,
          this.accionSeleccionadaData.idarticuloParaRecuperacion || null,
          this.accionSeleccionadaData.idalmacenLparte || null // idalmacenLparte
        )
        this.reloadDirty()
      } finally {
        this.$loading.hide()
      }
    },
    async almacenSelected (value) {
      this.accionSeleccionadaData['idalmacenLparte'] = value.idalmacen
      await this.marcarAccionEjecRealizada()
    },
    async clickAccion (data) {
      try {
        this.accionSeleccionadaData = data
        if (this.estadoPartePendiente && data.accion) {
          // comparación por igualdad abstracta == porque idparte_trabajo puede ser string o number
          if (!data.accion.idparte_trabajo || data.accion.idparte_trabajo == this.routeParams.idparte_trabajo) {
            if (data.accion.clasificacion === MACCION.clasificacion.sustitucion) {
              this.$alert.showSnackbarError('La sustitución debe realizarse desde la ficha del material afectado')
            } else {
              if (data.accion.idest_accion_ejec === ACCION_EJEC.estados.pendiente) {
                // si es recuperación, tengo que mandarle el artículo de facturación, que puede ser el mismo que el afectado
                if (data.accion.clasificacion === MACCION.clasificacion.recuperacion) {
                  this.accionSeleccionadaData['idarticuloParaRecuperacion'] = data.item.material_sistema.idarticulo
                  this.showingDialogs.selectAlmacenForm = true
                } else {
                  await this.marcarAccionEjecRealizada()
                }
              } else if (data.accion.idest_accion_ejec === ACCION_EJEC.estados.realizada) {
                this.$loading.showManual('Cambiando acción a pendiente...')
                await this.$offline.accionEjec.marcarAccionEjecNoRealizadaSync(
                  data.accion.idaccion_ejec,
                  this.idchecklistOt,
                  this.routeParams.idparte_trabajo
                )
              }
              this.reloadDirty()
            }
          } else {
            this.$alert.showSnackbarError(
              `No se puede modificar la acción "${data.accion.desc_acciones}" porque fue realizada en otro parte de trabajo`
            )
          }
        }
      } finally {
        this.$loading.hide()
      }
    },
    async clickItemAvatar (data) {
      this.rememberScroll = true
      this.rememberState = true
      const checklistOt = await this.$offline.parteTrabajoMatsist.selectChecklistOt(
        data.item.parte_trabajo_matsist.idparte_trabajo_matsist
      )
      if (checklistOt.idchecklist_ot) {
        this.$appRouter.push({
          name: 'offline__materiales-pregunta-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: checklistOt.idchecklist_ot,
            idorden_trabajo_matsist: data.item.orden_trabajo_matsist.idorden_trabajo_matsist,
          },
        })
      }
    },
    clickBuscarMaterial () {
      this.showingDialogs.buscarMaterialAfectado = !this.showingDialogs.buscarMaterialAfectado
    },
    clickBottomNavBarButton (data) {
      switch (data.button.name) {
      case 'ver':
        this.clickVerCompletado()
        break
      case 'buscar':
        this.clickBuscarMaterial()
        break
      default:
        console.error(`La opción ${data.button.name} no está contemplada`)
      }
    },
  },
}
</script>
